/** @jsx jsx */
import { Container, jsx, Styled, Box, Flex, useColorMode } from "theme-ui"
import { Circle, Donut } from "./shapes"
import { down, upWide } from "../styles/animations"
import Logo from "../icons/logo"
import CircleGrid from "../icons/circle-grid"

const AboutMe = () => {
  const [mode] = useColorMode()

  const isStrange = mode === `strangerThings`

  return (
    <section data-name="about-me" sx={{ position: `relative`, py: 5 }}>
      <Container>
        <div sx={{ maxWidth: `760px` }}>
          <Styled.h1 as="h2">About Us</Styled.h1>
          <Styled.p>
            Thanks for reading this far.{` `}
          </Styled.p>
          <Styled.p>
            We're JetPeak and <span sx={{ fontWeight: `bold` }}>we want to help creators take their hustle to the next level</span>.
          </Styled.p>
          <Styled.p>
            Talented, creative, and awesome people exist everywhere in the world. The current platforms simply aren't good enough.
            Being subject to the whims of an algorithm, payments processors, and moderators takes the power completely out of your hands.
          </Styled.p>
          <Styled.p>
            We believe that you're the expert in your domain. It's our duty to help you share your gift.
            What you need is a set of tools that enables you to do just that and then gets out of the way. 
            These tools should be accessible to everyone.{` `}
          </Styled.p>
          <Styled.p>
            We believe in using our work to make the world a better place. 
            Many of our past projects have been environmentally and socially oriented.
            If you're a creator focused on social enterprise, get in touch! We would love to support your initiatives.
          </Styled.p>
          <Styled.p>
            <span sx={{ fontWeight: `bold` }}>Your success is our success</span>. {` `}
          </Styled.p>
          {/*
          <Styled.p>
            If you're interested in learning how we can help you, you should definitely {` `}
            <Styled.a href="/discovery/">
              read about our process
            </Styled.a>
            .
          </Styled.p>
          */}
        </div>
      </Container>
      <Circle size={[`200px`, `200px`, `300px`]} color="red" top="-75px" right="-75px" />
      <Circle
        size={[`25px`, `25px`, `50px`]}
        color="gray"
        top="-25px"
        right={[`175px`, `175px`, `275px`]}
        sx={{ zIndex: 10 }}
      />
      <Circle
        size={[`15px`, `15px`, `25px`]}
        color="pink"
        top="50px"
        right={[`145px`, `145px`, `375px`]}
        sx={{ animation: `${upWide} 20s ease-in-out infinite alternate` }}
      />
      <Logo
        sx={{
          width: [70, 70, 90],
          height: [70, 70, 90],
          top: `105px`,
          right: [`40px`, `40px`, `240px`],
          position: `absolute`,
          color: `white`,
        }}
      />
      <Circle
        size={[`100px`, `100px`, `120px`]}
        color="darkIndigo"
        top="90px"
        right={[`25px`, `25px`, `225px`]}
        sx={{ boxShadow: `0 0 0 30px rgba(90, 103, 216, 0.15)` }}
      />
      <Donut color="yellow" size="200px" top="-100px" width="30px" left="-50px" sx={{ opacity: 0.5 }} />
      <Donut
        color="blue"
        size="50px"
        top="-25px"
        width="8px"
        left="180px"
        sx={{ opacity: 0.5, animation: `${down} 10s ease-in-out infinite alternate` }}
      />
    </section>
  )
}

export default AboutMe
