/** @jsx jsx */
import { Box, jsx } from "theme-ui"

import imagesrc from "../../static/digitalnomadhero.png"

const HeroGraphic = () => (
  <div sx={{ marginRight: `-75px` }}>
    <img
      src={imagesrc}
      alt="This could be you!"
      sx={{
        width: `550px`,
        borderRadius: `5%`,
      }}
    />
  </div>
)

export default HeroGraphic
