/** @jsx jsx */
import { Box, Container, Flex, jsx, Styled } from "theme-ui"
import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const NewsletterForm = () => {
	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(false);

	const validateEmail = (emailAddress: String) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(emailAddress.toLowerCase());
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (email.length && validateEmail(email)) {
			addToMailchimp(email).then(({ msg, result }) => {
				if (result !== 'success') {
					throw msg;
				}
				else {
					//setSuccess(true)
					window.location.href = `https://app.jetpeak.co/auth/register?email=${email}`;
				}
			})
			.catch((err) => {
				console.log('err', err);
				alert(err);
			});
			setEmail('');
		}
		else return;
	}
	return (
		<React.Fragment>
			{ success ? (
				<Box
					sx={{ variant: `buttons.primary`, mt: 2, display: `inline-block` }}
				>
					Awesome! You've successfully joined the Creator waitlist. 
					When you've been approved, we will let you know by email. 
					In the meantime, head over to the sign up page and create a user account.
					This account will be upgraded to full Creator abilities when you're verified. 
				</Box>
			) : (
				<React.Fragment>
					<input
						required
						type="email"
						value={email}
						onChange={e => setEmail(e.target.value)} 
						sx={{ variant: `inputs.primary`, mr: 2, display: `inline-block`, border: `solid 2px black` }} 
						placeholder="you@email.com" 
					/>
					<Box
						onClick={handleSubmit}
						sx={{ variant: `buttons.newsletter`, mt: 2, display: `inline-block` }}
					>
						Join the waitlist
					</Box>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

export default NewsletterForm;