/** @jsx jsx */
import { Flex, Box, jsx, Styled, Container, useColorMode } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { ChildImageSharp } from "../types"
import { Circle, Donut } from "./shapes"
import CircleGrid from "../icons/circle-grid"
import iconExternal from "../icons/icon-external-window.svg"
import Typewriter from "typewriter-effect";

type Props = {
  allThemes: {
    nodes: {
      title: string
      url: string
      preview: string
      description: string
      shapes: {
        color: string
        type: string
        size: string[]
        xOffset: string[]
        yOffset: string[]
        opacity: number
      }[]
      image: ChildImageSharp
    }[]
  }
}

const cardStyle = {
  position: `relative`,
  borderRadius: `lg`,
  transition: `all 0.3s ease-in-out`,
  display: `block`,
  
  zIndex: 2,
  "&:hover": {
    transform: `translateY(-4px)`,
    boxShadow: [`lg`, `lg`, `xl`],
    "[data-name='card-overlay']": {
      opacity: 1,
    },
  },
  ".gatsby-image-wrapper": {
    borderRadius: `lg`,
  },
  "[data-name='card-overlay']": {
    position: `absolute`,
    borderRadius: `lg`,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: `rgba(90, 103, 216, 0.9)`,
    color: `white`,
    fontFamily: `body`,
    fontSize: 4,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    transition: `all 0.3s ease-in-out`,
    opacity: 0,
  },
}

const Listing = () => {
  const {
    allThemes: { nodes: themes },
  } = useStaticQuery<Props>(ListingQuery)

  const [mode] = useColorMode()
  const isStrange = mode === `strangerThings`

  let buttonStyles = {}
  let overlayStyles = {}

  if (mode === `strangerThings`) {
    buttonStyles = {
      background: `black`,
      border: `2px solid #E7251D`,
    }
    overlayStyles = {
      backgroundColor: `rgba(231, 37, 29, 0.9)`,
    }
  }

  return (
    <Container sx={{ py: 4 }}>
      {themes.map((theme, index) => {
        const isEven = index % 2 === 0

        return (
          <Box
            key={theme.title}
            sx={{
              py: [5, 5, 6],
              display: `grid`,
              gridTemplateColumns: [`1fr`, `1fr`, `1fr 1fr`],
              gridGap: [3, 4, 5],
              alignItems: `flex-start`,
            }}
          >
            <div
              sx={{
                width: `100%`,
                maxWidth: [`600px`, `900px`, `600px`],
                position: `relative`,
                order: isEven ? 1 : [1, 1, 2],
              }}
            >
              {theme.shapes.map(shape => {
                switch (shape.type) {
                  case `circle`:
                    return (
                      <Circle
                        key={`${shape.size}-${shape.color}-${shape.xOffset}`}
                        size={shape.size}
                        color={shape.color}
                        left={isEven ? shape.xOffset : `unset`}
                        right={isEven ? `unset` : shape.xOffset}
                        top={shape.yOffset}
                        sx={{ zIndex: 0, opacity: shape.opacity }}
                      />
                    )
                  case `donut`:
                    return (
                      <Donut
                        key={`${shape.size}-${shape.color}-${shape.xOffset}`}
                        width="20px"
                        size={shape.size}
                        color={shape.color}
                        left={isEven ? shape.xOffset : `unset`}
                        right={isEven ? `unset` : shape.xOffset}
                        top={shape.yOffset}
                        sx={{ zIndex: 0, opacity: shape.opacity }}
                      />
                    )
                  default:
                    return null
                }
              })}
              <a
                href={theme.preview}
                rel="noopener noreferrer"
                target="_blank"
                aria-label={`Visit a preview of theme ${theme.title}`}
                sx={{
                  ...cardStyle,
                  "[data-name='card-overlay']": {
                    ...cardStyle[`[data-name='card-overlay']`],
                    ...overlayStyles,
                  },
                }}
              >
                <div data-name="card-overlay" aria-hidden>
                  <div sx={{ display: `flex`, alignItems: `center` }}>
                    <img width="40" height="40" sx={{ mr: 3 }} alt="" src={iconExternal} /> Dive In
                  </div>
                </div>
                <Img fluid={theme.image.childImageSharp.fluid} />
              </a>
            </div>
            <Flex sx={{ flexDirection: `column`, alignItems: `flex-start`, order: isEven ? 2 : [2, 2, 1] }}>
              <Styled.h1 as="h3">{theme.title}</Styled.h1>
              <Styled.p>{theme.description}</Styled.p>
              {/*<a href={theme.url} sx={{ variant: `buttons.primary`, mt: 3, ...buttonStyles }}>
                Check It Out
              </a>*/}
            </Flex>
          </Box>
        )
      })}
      <Container sx={{ mt: [9, 1], position: `relative` }}>
        <Box
          sx={{
            borderRadius: `xl`,
            zIndex: 3,
            position: `relative`,
            variant: isStrange ? `gradients.strangerThings` : `gradients.indigo`,
            p: [4, 4, 5],
            color: `white`,
            boxShadow: (t: { colors: { [key: string]: any[] } }) => `0px 20px 25px ${t.colors.shadow}`,
          }}
        >
          <h3
            sx={{
              fontWeight: `bold`,
              fontSize: [2, 3, 4],
              textAlign: `center`,
              mb: 4,
              mt: 0,
              textShadow: `0 1px 1px rgba(0, 0, 0, 0.2)`,
            }}
          >
            <Typewriter
              options={{
                strings: ['Earn More Money', 'Keep More Money', 'Own Your Brand', 'Own Your Audience'],
                autoStart: true,
                loop: true,
                delay: 75
              }}
            />
          </h3>
          <Styled.p sx={{ textShadow: `0 1px 1px rgba(0, 0, 0, 0.2)`, textAlign: `center`, }}>
            Want to know how much you could increase your income?
          </Styled.p>
          <Flex
            sx={{
              justifyContent: `space-evenly`,
              mt: 4,
              flexWrap: `wrap`,
              a: { mt: 3, textAlign: `center` },
              div: { mt: 3 },
              flexDirection: [`column`, `row`],
            }}
          >
            <a href="https://app.jetpeak.co/auth/register" sx={{ variant: `buttons.outline` }}>
              Yes, I'd like to make & keep more money
            </a>
          </Flex>
        </Box>
        <Circle
          color="orange"
          size={[`60px`, `80px`, `130px`]}
          top={[`0px`, `-30px`, `-60px`]}
          right={[`20px`, `70px`]}
          sx={{ zIndex: 10 }}
        />
        <Circle
          color="green"
          size={[`20px`, `25px`, `30px`]}
          top={[`-10px`, `-40px`, `-80px`]}
          right={[`100px`, `170px`, `220px`]}
          sx={{ zIndex: 10 }}
        />
        <Donut
          color="pink"
          size={[`300px`, `340px`]}
          top="150px" width="40px"
          right="-160px"
          sx={{ opacity: 0.35, zIndex: 1 }}
        />
        <CircleGrid
          color="blue"
          size={220}
          top="-20px"
          left={[`-180px`, `-120px`, `-120px`]}
          sx={{ zIndex: 0 }}
        />
      </Container>
    </Container>
  )
}

export default Listing

const ListingQuery = graphql`
  query {
    allThemes(sort: { fields: title, order: DESC }) {
      nodes {
        title
        url
        preview
        description
        shapes {
          color
          type
          size
          xOffset
          yOffset
          opacity
        }
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
